* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
  }

  body {
    background-color: #f8f9fa;
  }

  .container {
    display: flex;
    min-height: 100vh; /* Fill the viewport height */
  }

  /* Left Section */
  .left-section {
    flex: 1;
    background-color: #fff;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .left-section h2 {
    margin-bottom: 20px;
    font-size: 24px;
  }

  /* Role Buttons */
  .role-buttons {
    display: flex;
    gap: 10px; /* Spacing between buttons */
    margin-bottom: 30px;
    flex-wrap: wrap; /* Allows wrapping on smaller screens if needed */
  }

  .role-buttons button {
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
  }

  .role-student {
    background-color: #e0ecff; /* Pastel Blue */
    color: #333;
  }
  .role-parent {
    background-color: #f8e8fe; /* Pastel Pink */
    color: #333;
  }
  .role-teacher {
    background-color: #ecfde8; /* Pastel Green */
    color: #333;
  }

  /* Form Fields */
  form {
    max-width: 300px;
    margin-bottom: 20px;
  }

  .form-group {
    margin-bottom: 15px;
  }

  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #555;
  }

  .form-group input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
  }

  .login-button {
    background-color: #1976d2;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }

  .forgot-password {
    margin-top: 10px;
    text-align: right;
  }

  .forgot-password a {
    text-decoration: none;
    color: #1976d2;
    font-size: 14px;
  }

  /* Right Section */
  .right-section {
    flex: 1;
    background: linear-gradient(to right, #6a11cb, #2575fc);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
    color: #fff;
  }

  .illustration {
    max-width: 300px;
    text-align: center;
  }

  .illustration img {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 5px;
  }

  .illustration h3 {
    margin-bottom: 10px;
    font-size: 20px;
  }

  .illustration p {
    font-size: 14px;
    line-height: 1.5;
  }

  /* Responsive Styles */
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
    }
    .left-section,
    .right-section {
      width: 100%;
      min-height: auto;
    }
    .left-section {
      padding: 20px; /* Adjust padding on smaller screens */
    }
    .right-section {
      padding: 20px;
    }
  }